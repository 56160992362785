@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&family=Hind:wght@300;400;500;700&family=Karla:wght@300;400;500;700&family=Dosis:wght@300;400;500;700&family=Barlow:wght@300;400;500;700&family=Inter:wght@300;500;700&family=Montserrat:wght@300;500;700&family=Open+Sans:wght@300;400;500;700&family=Roboto:wght@300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "animate.css";

@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "slick-carousel/slick/slick.css";


body {
    background-image: url(../img/brick-wall-bg3.jpg);
}

@layer components {
    .btn-primary {
        @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
    }
} 

@import "./display-editor.scss";
@import "./display-list.scss";
