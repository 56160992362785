.image-slider {
    
    .slick-dots {
        position: absolute;
        bottom: 1em;
        left: 0;
        width: 100%;
        display: flex !important;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin: 0px 2px;
        }

        button {
            text-indent: -9999px;
            width: .5em;
            height: .5em;
            border-radius: 50%;
            background-color: #000;
            opacity: 0.5;
        }

        li.slick-active button {
            opacity: 1;
        }
    }
}