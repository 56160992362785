/* Chrome, Safari, Edge, Opera */
input.hide-spinner::-webkit-outer-spin-button,
input.hide-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number].hide-spinner {
  -moz-appearance: textfield;
}