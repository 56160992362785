.viewport {
    // background-image: url(../img/brick-wall-bg.jpg);
    // position: relative;
}

.viewport::after {
    content: "";
    // background: radial-gradient(circle at center, transparent 25%, rgb(240 240 240 / var(--tw-bg-opacity)) 100%);
    
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.viewport > * {
    position: relative;
    z-index: 1;
}

.display-canvas {
    padding-top: 56.25%;

    .display-canvas-inner {
        // font-size: clamp(0.625rem, 1vw, 1rem);
        font-size: .6vmax;
    }

    .display-header {
        padding: 1em;
    }

    .display-footer {
        padding: 1em;
    }

    .text-sm { font-size: 0.875em; }
    .text-lg { font-size: 1.125em; }
    .text-xl { font-size: 1.25em; }
    .text-2xl { font-size: 1.5em; }

}