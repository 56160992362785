.display-index {
    // background-image: url(../img/brick-wall-bg.jpg);
    // background-repeat: repeat;
    // background-size: 75% auto;
    position: relative;

    // &::after {
    //     content: "";
    //     // background: radial-gradient(circle at center, transparent 25%, rgb(240 240 240 / var(--tw-bg-opacity)) 100%);
    //     background: linear-gradient(to right, transparent 0%, #eee 50%, transparent 100%);
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 0;
    // }

    > * {
        position: relative;
        z-index: 1;
    }

    .display-list-preview-inner {
        width: 100%;
        height: 0;
        padding-top: 56.25%;
    }

    .display-list-preview.display-offline .display-list-preview-inner {
        background: url(../img/fuzzy.gif) repeat;
        background-size: 50%;
        // filter: brightness(1.3);

        // background: #333;
        opacity: 0.2;
    }
}