.services-slider {

    .slick-arrow {
        position: absolute;
        top: 50%;
        width: 5rem;
        height: 5rem;
        text-indent: 99999px;
        transform: translateY(-50%);

        &.slick-disabled {
            visibility: hidden;
        }
    }

    .slick-next {
        left: -100px;
        background-size: cover;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23888888' stroke-width='0.5'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 19l-7-7 7-7' /%3E%3C/svg%3E");
    }

    .slick-prev {
        right: -100px;
        background-size: cover;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23888888' stroke-width='0.5'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 5l7 7-7 7' /%3E%3C/svg%3E");
    }


}